import { ContentBlocks } from "@/ui/ContentBlocks";
import { SeoHead } from "@/ui/SeoHead";

export default function ErrorPage({ prefix, title, canonical, data }) {
  return (
    <>
      <SeoHead title={title} canonical={canonical} />
      <ContentBlocks data={data} prefix={prefix} />
    </>
  );
}

export async function getStaticProps() {
  return {
    props: {
      prefix: "error",
      title: "404",
      canonical: "/404/",
      data: [{ component: "Error", props: { className: "py-7" } }],
    },
  };
}
